@font-face {
    font-family: 'Sora-regular';
    src: url(../src/Fonts/TTF/Sora-Regular.ttf);
}

@font-face {
    font-family: 'Sora-bold';
    src: url(../src/Fonts/TTF/Sora-Bold.ttf);
}

:root {
    --main-font: 'Sora-bold';
    --header-color-1: #0596cc;
    --header-color-2: #2A68B3;
    --body-background: #fff;
    --body-color-1: #6495EC;
    --body-color-2: #6495EC;
    --body-color-3: #408BEF;
    --body-color-4: #edf3fd;
    --button-bg-color-1: #6495EC;
    --button-bg-color-2: #2A68B3;
    --button-bg-color-3: #595959;
    --button-font-color-1: #fff;
    --button-font-color-2: #111111;
    --border-color-1: #edf3fd;
    --border-color-2: rgba(17, 17, 17, 0.5);
    --font-color-1: #2A68B3;
    --font-color-2: #111111;
    --font-color-3: #595959;
    --font-color-4: #111111;
    --form-bg-color-1: #fff;
    --form-font-color-1: #969696;
    --icon-color-1: #fff;
    --icon-color-2: #2A68B3;
    --popup-bg-color-1: #0F8FFD;
    --popup-font-color-1: #fff;
    --popup-button-bg-color-2: #2A68B3;
    --popup-button-font-color-2: #fff;
    --card-bg-color-1: #6495EC;
    --card-bg-color-2: #6495EC;
    --loader-color: #2A68B3;
}