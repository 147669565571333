@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Poppins", sans-serif;
}

::selection {
  color: #fff;
  background: #d43f8d;
}
.stepper-container {
  margin: auto;
  /* width: 75%; */
  text-align: center;
  border-radius: 5px;
  padding: 50px 35px 10px 35px;
}
.stepper-container header {
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 30px 0;
}
.stepper-container .form-outer {
  width: 100%;
  overflow: hidden;
}
.stepper-container .form-outer form {
  display: flex;
  width: 400%;
  margin-bottom: 100px;
}
.form-outer form .page {
  width: 25%;
  transition: margin-left 0.3s ease-in-out;
}
.form-outer form .page .title {
  text-align: left;
  color: #fff;
  /* font-family: Sora; */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.form-outer form .page .field {
  /* width: 330px; */
  /* height: 45px; */
  margin: 45px 0;
  /* display: flex; */
  position: relative;
  margin-top: 51px;
}
form .page .field .label {
  position: absolute;
  top: -30px;
  color: #c6c8da;
  /* font-family: Sora; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
form .page .field input {
  height: 50px;
  width: 100%;
  padding-left: 15px;
  font-size: 18px;
  border-radius: 10px;
  background: #d9d9d9;
}
form .page .field select {
  width: 100%;
  padding-left: 10px;
  font-size: 17px;
  font-weight: 500;
}

form .page .field textarea {
  background: #d9d9d9;
  border-radius: 10px;
  padding-left: 15px;
  font-size: 18px;
}
form .page .field textarea:focus{
  background: #d9d9d9;
}
/* form .page .field button {

  border: none;
  background: #d33f8d;
  margin-top: -20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s ease;
} */
/* form .page .field button:hover {
  background: #000;
} */
form .page .btns button {
  margin-top: -20px !important;
}
form .page .btns button.prev {
  margin-right: 3px;
  font-size: 17px;
}
form .page .btns button.next {
  margin-left: 3px;
}
.stepper-container .stepper-progress-bar {
  display: flex;
  margin: 40px 0;
  user-select: none;
}
.stepper-container .stepper-progress-bar .step {
  text-align: center;
  width: 100%;
  position: relative;
}
.stepper-container .stepper-progress-bar .step p {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 8px;
}
.stepper-progress-bar .step .bullet {
  height: 25px;
  width: 100%;
  /* border: 2px solid #000; */
  display: inline-block;
  /* border-radius: 50%; */
  position: relative;
  transition: 0.2s;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
}
/* .stepper-progress-bar .step .bullet.active{
  border-color: #d43f8d;
  background: #d43f8d;
} */
/* .stepper-progress-bar .step .bullet span{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
} */
.stepper-progress-bar .step .bullet.active span {
  /* display: none; */
}
.stepper-progress-bar .step .bullet:before,
.stepper-progress-bar .step .bullet:after {
  position: absolute;
  content: "";
  bottom: -16px;
  right: -70px;
  /* height: 3px; */
  width: 125px;
  background: #262626;
  border: 2px dashed #ffc300;
  border-width: 1px;
}
/* .stepper-progress-bar .step .bullet.active:after{
  background: #d43f8d;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.3s linear forwards;
} */
@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}
.stepper-progress-bar .step:last-child .bullet:before,
.stepper-progress-bar .step:last-child .bullet:after {
  display: none;
}
.stepper-progress-bar .step p.active {
  color: #d43f8d;
  transition: 0.2s linear;
}
.stepper-progress-bar .step .check {
  position: absolute;
  left: 50%;
  top: 70%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  display: none;
}
.stepper-progress-bar .step .check.active {
  display: block;
  color: #fff;
}

.step-inner-label {
  /* margin-left: 40px; */
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.step-inner {
  display: inline-flex;
  align-items: center;
}

.first_h {
  color: #ffc300;
  /* font-family: Sora; */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

.second_h {
  padding-top: 10px;
  color: #c6c8da;
  text-align: justify;
  /* font-family: Sora; */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 156.25% */
  letter-spacing: 0.32px;
}

.step-button-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.field.btns {
  display: block !important;
  margin: 0 !important;
}

.field.btns .next {
  box-shadow: -5px 6px #ffc300;
  background: #0f0c04;
  border: 2px solid #ffc300;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* width: 100%; */
  height: calc(100% + 5px);
  color: #ffc300;
  padding: 10px 24px;
  float: right;
}
.field.btns .prev {
  float: left;
}

.back-btn {
  color: #ffc300;
  /* font-family: Sora; */
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: unset;
  display: inline-flex;
}

.back-btn .icon {
  display: inline-block;
  width: 20px; /* Adjust the width of the image as needed */
  height: 20px; /* Adjust the height of the image as needed */
  background-image: url(../assets/Group\ 83.svg);
  background-size: cover; /* Adjust as needed, cover will resize the image to fill the container */
  margin-right: 10px; /* Add some space between the icon and the text */
  margin-top: 5px;
}

.back-btn:hover {
  background-color: unset;
}

.error {
  text-align: left;
  color: red;
  font-size: 13px;
}

.drop-zone {
  /* max-width: 200px; */
  height: 200px;
  padding: 25px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 3px dashed rgba(255, 195, 0, 0.80);
  border-radius: 10px;
  padding-top: 50px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.drop-zone__prompt1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.profile-pic {
  position: relative;
}

.profile-pic__container {
  position: relative;
  cursor: pointer;
}

.profile-pic__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.profile-pic__container:hover .profile-pic__overlay {
  opacity: 1;
}

.profile-pic__button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
