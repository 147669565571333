.storeMain {
    align-items: center;
}

.storeName,
.new-arrivals {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
}

.store-tabs,
.new-arriaval-slider {
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.storeName h1 {
    margin-top: 50px;
    color: #ffffff;
    font-family: "Sora-bold" !important;
    font-size: 25px;
    text-transform: capitalize;
    text-align: center;
}

.storeBanner .storeBanner-image {
    width: 100%;
    height: 400px;
}

.storeBanner {
    position: relative;
}

.avatar-card {
    background: linear-gradient(-45deg, var(--body-color-1), var(--body-color-2));
    height: 250px;
    position: absolute;
    top: 70%;
    left: 7%;
    width: 85%;
    border-radius: 15px;
    border: none;
}

.avatar-card .avatar-card-body div {
    position: relative;
}

.avatar-card .avatar-card-body .avatar-img {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -50%);
}

.store-desc {
    color: #ffffff;
    font-size: 16px;
    font-family: "Sora-regular" !important;
    text-align: center;
    padding: 10px 25px;
    line-height: 23px;
    text-transform: capitalize;
}

.social-icons {
    position: absolute;
    margin-top: 18px;
    left: 50%;
    /* Adjusted to 50% */
    transform: translate(-50%, -50%);
    /* Centering using translate */
}

.social-icons li img {
    text-align: center;
    width: 25px;
}

.social-icons li {
    display: inline-flex;
    padding-right: 20px;
}

.new-arrivals {
    margin-top: 250px;
}

.new-arrivals h1 {
    font-family: "Sora-bold" !important;
    font-size: 22px;
    color: var(--font-color-1);
}

.store-tabs ul {
    border-bottom: none;
}

.store-tabs ul li {
    padding: 15px 15px;
}

.store-tabs ul li button:hover {
    border: none;
    transition: none !important;
    transform: none !important;
    border-color: transparent !important;
}

.store-tabs ul li button:focus {
    border: none !important;
    border-color: transparent !important;
}

.store-tabs ul li button {
    width: 150px;
    text-align: center;
    padding: 15px 5px 15px 5px !important;
    font-family: "Sora-bold" !important;
    background-color: transparent !important;
    color: var(--button-font-color-2) !important;
    font-size: 21px !important;
    border-color: transparent !important;
}

.store-tabs ul li .active {
    color: var(--button-font-color-1) !important;
    background-color: var(--button-bg-color-1) !important;
    border: none;
}

.store-product-card {
    /* margin-top: 30px; */
    background: var(--body-color-4);
    border: 2px solid var(--border-color-1);
    border-radius: 15px;
    /* padding: 5px 25px; */
    /* margin-left: 20px;
    margin-right: 20px; */
}

.store-product-card-body {
    text-align: center;
}

.store-product-img {
    margin: auto;
    /* padding: 15px; */
}

.store-product-name {
    padding-top: 20px;
    padding-bottom: 10px;
    color: var(--font-color-2);
    font-size: 22px;
    font-family: "Sora-regular" !important;
}

.store-product-price {
    display: inline-flex;
}

.store-product-price h3 {
    color: var(--font-color-1);
    font-family: "Sora-bold" !important;
    font-size: 18px;
    padding-left: 10px;
}

.store-product-price img {
    /* width: 25px; */
    height: 19px;
}

.new-view-all {
    display: inline-flex;
    float: right;
}

.new-view-all {
    font-family: "Sora-bold" !important;
    font-size: 25px;
    color: #ffc300;
}

.new-view-all img {
    width: 25px;
    margin-left: 20px;
    height: 30px;
}

.kVtMtA,
.bhRnqO {
    width: 45px;
    height: 45px;
}

.kVtMtA {
    background: linear-gradient(180deg, #ffc40036, #ffc4003c);
    left: -10px;
    border: 1px solid #ffc4005f;
}

.bhRnqO {
    background: linear-gradient(180deg, #ffc40036, #ffc4003c);
    right: -10px;
    border: 1px solid #ffc4005f;
}

.search-product-input {
    padding-left: 60px;
    width: 30%;
    float: right;
    margin-top: 17px;
    background: #333232;
    color: #ffffff;
    border: none;
    font-family: "Sora-regular" !important;
}

.search-product-input::placeholder {
    color: var(--font-color-1);
    font-family: "Sora-regular" !important;
}

.search-product-input:focus {
    font-family: "Sora-regular" !important;
    background: #333232;
    color: #ffffff;
    border: none;
}

.search-products {
    position: relative;
}

.search-icon {
    float: right;
    position: absolute;
    right: 27%;
    margin-top: 27px;
}

.no-products h4 {
    color: var(--font-color-3);
    font-family: "Sora-regular" !important;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
}


/* .yourFixedDiv.fixed {
    position: fixed;
    top: 0;
}
*/

@media only screen and (max-width: 600px) {
    .storeMain {
        margin-top: 5%;
    }
    .social-icons {
        display: flex;
        justify-content: center;
        position: unset;
        margin-top: unset;
        left: unset;
        transform: unset;
    }
    .storeBanner .storeBanner-image {
        width: 100%;
        height: 150px;
    }
    .store-tabs ul {
        justify-content: center;
    }
    .store-tabs ul li button {
        width: 100px;
        padding: 10px 5px 10px 5px !important;
        font-size: 16px !important;
    }
    .search-product-input {
        padding-left: 15px;
        width: 100%;
        float: unset;
        margin-top: unset;
    }
    .search-icon {
        right: 4%;
        margin-top: 10px;
    }
    .store-product-name {
        font-size: 14px;
    }
}