.dashboard-title h1 {
    font-size: 35px;
    color: #ffffff;
}

.order-details-header h2 {
    color: #ffffff;
    font-size: 25px;
}

.dashboard-design {
    margin-top: 2%;
    margin-bottom: 2%;
}

.fixed-height {
    height: 190px;
}

.sora-regular {
    font-family: 'Sora-regular' !important;
}

.sora-bold {
    font-family: 'Sora-bold' !important;
}

.btn-tranparent,
.btn-tranparent:hover {
    background: transparent;
    border: 2px solid #ffc300;
    border-radius: 5px;
    margin-top: 10px;
}

.shawdow-btn {
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
    font-size: 18px;
    box-shadow: -4px 10px 6px #ffc300, -4px 3px 6px #ffc300;
}

.shawdow-btn:hover {
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
}

.customize {
    color: #ffffff;
    font-size: 20px;
}

.chart-div {
    margin-top: 2%;
    margin-bottom: 3%;
}

.yellow-card h4 {
    color: #ffc300;
    font-size: 24px;
}

.yellow-card p {
    margin-top: 15px;
    color: #ffffff;
    font-size: 16px;
}

.design-content {
    padding: 5%;
}

.yellow-card {
    background: linear-gradient(to bottom right, rgb(0 0 0 / 0%), #ffc40061);
    border-radius: 20px;
    border: none;
}

.light-card {
    background: #0000007a;
    border-radius: 20px;
    border: none;
}

.dark-card {
    background: #000000;
    border-radius: 20px;
    border: none;
    z-index: 1;
}

.dark-card .card-body {
    padding: 35px;
}

.orders-icon {
    background: #292200;
    border-radius: 25px;
    padding: 5% 5% 5% 5%;
}

.order-details-cards {
    margin-top: 3%;
    margin-bottom: 3%;
}

.orders-details {
    padding-left: 15%;
}

.orders-details h3 {
    font-size: 20px !important;
    color: #ffffff;
}

.orders-details p {
    font-size: 16px !important;
    color: #ffffff7c;
}

.orders-details h3 {
    font-family: "Oswald-Bold";
}

.orders-details p {
    font-family: "Nunito-Regular";
    margin-bottom: 0;
}

.orders-content {
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 2%;
    padding-right: 2%;
}

.chart-heading p {
    font-size: 16px;
    color: #ffffff7e;
}

.chart-heading h3 {
    font-size: 18px;
    color: #ffffff;
}

.chart-data {
    padding-top: 3%;
    padding-bottom: 10%;
}

.chart-select:focus,
.chart-select:active,
.chart-select {
    border: none;
    box-shadow: none;
}

.background-border-div {
    position: relative;
}

.background-border1:after,
.background-border2:after,
.background-border3:after,
.background-border4:after {
    border-radius: 20px;
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: #fff;
    z-index: 0;
    /* filter: blur(20px); */
}

.background-border1:after {
    background: linear-gradient(335deg, #0a0216, #796710);
}

.background-border2:after {
    background: linear-gradient(45deg, #0a0216, #9444de);
}

.background-border3:after {
    background: linear-gradient(135deg, #0a0216, #08ffc8);
}

.background-border4:after {
    background: linear-gradient(335deg, #0a0216, #ffea20);
}