footer {
    background: linear-gradient(270deg, #141414 -4.42%, #201A07 105.38%);
    position: fixed;
    width: 100%;
    bottom: 0px;
}

.mobile-social-icon a img {
    max-width: 20px;
    /* width: 16px; */
    /* height: 18px; */
    margin: auto;
}

.mobile-social-icon a {
    background-color: unset;
    border-radius: unset;
}