.header-style-1 .search-style-2 form {
    margin: auto;
}

.search-style-2 form select {
    width: 200px;
}

.header-style-1 .search-style-2 form {
    border: 2px solid #000000;
}

.header-style-1 .main-categori-wrap>a {
    background: #2180cb;
}

.wallet-adapter-button {
    background-color: #ffc300 !important;
    color: #000 !important;
}

.mobile-promotion {
    background: #053c9b;
}

.color-white {
    color: #fff;
}

.header-top-ptb-1 {
    background: linear-gradient(135deg, #0596cc 0%, #1e4395 100%) !important;
}

.header-info>ul>li a {
    color: #fff;
    text-decoration: unset;
    font-weight: 400;
    font-size: 16px;
}

.twitter-handle {
    margin-left: 10px;
    border: 1px solid #6c757d;
    padding: 5px;
    cursor: pointer;
}

.logo-text {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    line-height: 22px;
}

.header-middle {
    background: linear-gradient(270deg, #141414 -4.42%, #201a07 105.38%);
}

.mobile-toggle {
    display: none;
}

.header-style-1.header-height-2 {
    background-color: var(--header-color-2);
}

.header-style-1 .header-bottom-bg-color {
    background: var(--header-color-2);
    /* background-color: var(--header-color-3); */
    border: unset;
}

.header-action-right img {
    width: 35px;
    margin-left: 10px;
}

.back-arrow {
    width: 25px;
}

@media only screen and (max-width: 600px) {
    .header-style-1 .header-middle-ptb-1 {
        padding: 20px 0;
    }
    .mobile-toggle {
        display: block;
    }
    .sticky-bar {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
    }
}

.cart-amt {
    font-size: 12px;
    background: var(--body-background);
    color: var(--font-color-1);
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
    border-radius: 50px;
}

.header-left {
    display: flex;
}

.header-info {
    width: 100%;
}

.header-info>ul>li:before {
    content: unset;
}

.header-info>ul>li {
    padding-right: 30px;
    margin-right: unset;
}

.header-info>ul>li:hover>a {
    color: #ffc300;
}

.header-cart-icon-container {
    background: #3a2e21;
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
}

.header-cart-icon {
    color: #fff;
    font-size: 30px;
}

.header-info>ul {
    margin-bottom: unset;
}

.cart-amt-web {
    position: absolute;
    top: -2px;
    right: -2px;
    font-size: 14px;
    background: var(--body-background);
    color: var(--font-color-1);
    padding: 0 5px;
    border-radius: 50px;
}