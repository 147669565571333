.banner-img .banner-text h4 {
    background: #26283bbd;
    padding: 15px;
    border-radius: 10px;
    min-height: unset;
    color: #fff;
}

.section-title h3 {
    color: #fff;
}

.home-cat-shop-now-btn {
    font-size: 18px !important;
}

.spinner-container-v1 {
    /* width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  background-color: #ffffff; */
    position: relative;
    text-align: center;
    margin-bottom: 100px;
}

.spinner-v1 {
    margin: auto;
    border: 4px solid #dbf2ff;
    /*
change to dotted for something cool.
change width to 1px for tapered bar
*/
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 45%;
    border-radius: 50%;
    border-right: 4px solid #018df7;
    text-align: center;
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    /*animation-timing-function: linear; /*linear spin */
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.product-cart-wrap .product-card-bottom .add-cart .add,
.product-cart-wrap .product-card-bottom .add-cart .add:hover {
    background-color: #018df7;
    color: #fff;
}