.custom-menu-item {
    color: #ffffffcc !important;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    font-family: 'Sora-bold';
    font-weight: 800;
}

.custom-menu-item a:hover {
    color: #ffc300 !important;
    background-color: #292200 !important;
    /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
}

#sideBar {
    background: #0c0b03fe !important;
    border-right: 1px solid #ffc4008d;
    z-index: 2;
}

.ps-sidebar-container {
    background: #0c0b03fe !important;
    /* border-right: 1px solid #ffc4008d; */
}

.ps-menu-label {
    display: flex;
    font-size: 18px;
    font-family: 'Sora-bold';
}

.ps-menu-label img {
    padding-right: 20px;
    padding-left: 5px;
}

.custom-menu-item-active {
    color: #ffc300 !important;
    background-color: #292200 !important;
    background: #292200 !important;
    border-left: 3px solid #ffc300;
    /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
    z-index: 1;
    font-family: 'Sora-bold';
    font-weight: 800;
}

.custom-menu-item-active a {
    background: #292200;
    /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
    margin-left: 2px;
    font-family: 'Nunito';
    font-weight: 800;
}

.custom-menu-item a span h5 {
    font-family: 'Nunito';
    font-weight: 800;
}

.oswald-font {
    font-family: 'Oswald';
}

.nutino-font {
    font-family: 'Nunito';
}

.active-sidebar-icons {
    font-size: 25px;
    color: #ffc300;
    margin-right: 15px;
}

.non-active-sidebar-icons {
    font-size: 25px;
    margin-right: 15px;
}