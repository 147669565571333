body {
    background: var(--body-background) !important;
    /* background: linear-gradient(to bottom right, #a27c0233, rgb(0 0 0 / 70%)); */
}

.mainpage-dashboard {
    padding-top: 3%;
    padding-left: 17%;
    padding-right: 3%;
    /* background: linear-gradient(to bottom right, #a27c0233, rgb(0 0 0 / 70%)); */
}

.mainPage {
    padding-top: 3%;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 3%;
    /* background: linear-gradient(to bottom right, #a27c0233, rgb(0 0 0 / 70%)); */
}

@font-face {
    font-family: 'Sora-regular';
    src: url(../src/Fonts/TTF/Sora-Regular.ttf);
}

@font-face {
    font-family: 'Sora-bold';
    src: url(../src/Fonts/TTF/Sora-Bold.ttf);
}

@media only screen and (max-width: 600px) {
    .mainPage {
        padding-top: 15%;
    }
}