.add-products .label {
    color: #fff;
    font-size: 20px;
}

.add-products .field {
    padding-bottom: 30px;
}

.add-products input {
    padding-left: 10px;
}

.add-products .form-outer {
    padding-bottom: 50px;
}

.product-addition-form input::placeholder,
.product-addition-form input::-webkit-input-placeholder {
    color: #969696 !important;
}

.product-addition-form .card .card-header {
    background-color: #292200;
}