.card {
    background-color: unset !important;
    /* border: 1px solid #3A2E21 !important; */
}

.card .card-header {
    background-color: #231F11;
    border-bottom: unset;
}

.card-header h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: hsl(0, 0%, 100%);
}

.content-title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #FFC300;
    padding-top: 20px;
    padding-bottom: 20px;
}

.form-label {
    margin-bottom: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #C6C8DA;
}

.form-control {
    background: var(--form-bg-color-1) !important;
    border: 1px solid #3A2E21 !important;
    border-radius: 10px !important;
    color: var(--form-font-color-1) !important;
}

.form-select {
    background: #231F11 !important;
    border: 1px solid #3A2E21 !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.4) !important;
    height: 48px !important;
}

.form-select option {
    background: unset;
}

.form-control::-webkit-file-upload-button {
    background-color: #0F0C04 !important;
    color: rgba(255, 255, 255, 0.4) !important;
    margin-top: 0px !important;
}

.css-13cymwt-control {
    background-color: #231F11 !important;
    border: 1px solid #3A2E21 !important;
}

.css-1p3m7a8-multiValue {
    background-color: #ffc300 !important
}

.css-wsp0cs-MultiValueGeneric {
    color: #000 !important;
}

.css-1u9des2-indicatorSeparator {
    background-color: #3A2E21 !important;
}

.css-1xc3v61-indicatorContainer {
    color: rgba(255, 255, 255, 0.4) !important;
}

.create-product-btn {
    box-shadow: -5px 6px #FFC300;
    background: #0F0C04;
    border: 2px solid #FFC300 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    /* identical to box height */
    color: #FFC300 !important;
}

.create-product-btn:hover {
    background: #0F0C04;
}